<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }"
                          :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')"
                          v-model="userdepidCascaderSelected"
                          :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll"
                          placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="年份(可多选)" prop="year" style="width: 25%">
              <a-select mode="multiple" v-model="queryParams.yearList">
                <a-select-option v-for="(item, index) in yearOptions" :key="index" :value="item">{{ item + '年' }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="能耗分类" prop="classification" style="width: 25%">
              <a-select v-model="queryParams.classification">
                <a-select-option value="">全部</a-select-option>
                <a-select-option v-for="(item, index) in ecClassifyList" :key="index" :value="item">{{ item }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开
                <a-icon :type="showAdvanced?'up':'down'"></a-icon>
              </a-button>
            </div>
            <div v-show="showAdvanced" class="advanced-query">
              <a-form-model-item label="仅显示实际用量告警项" prop="warning_status" style="width: 25%">
                <a-select v-model="queryParams.warning_status">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option value="2">是</a-select-option>
                  <a-select-option value="3">否</a-select-option>
                </a-select>
              </a-form-model-item>
            </div>

          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">能耗历史分析<h5 style="color: red">说明:红色字体表示"当年实际数"与"当年计划数"偏差大于5%</h5></h3>
          <div class="table-btns">
            <!--            <a-button type="primary" @click="showModal('add')"><a-icon type="plus"></a-icon>新建</a-button>-->
            <a-button v-if="btnList.includes('导出')" @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>
            <a-button v-if="btnList.includes('生成图表')" @click="showChartModal" style="margin-right: 10px"><a-icon type="export"></a-icon>生成数据图表</a-button>
<!--            <a-button @click="exportModal" style="margin-right: 10px">-->
<!--              <a-icon type="export"></a-icon>-->
<!--              批量导出-->
<!--            </a-button>-->
<!--            <a-button @click="showChartModal" style="margin-right: 10px">-->
<!--              <a-icon type="export"></a-icon>-->
<!--              生成数据图表-->
<!--            </a-button>-->
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :customRow="customRow" :columns="tableColumns" :data-source="tableData"
                 :loading="tableLoading"
                 :scroll="{ x: 2000}"
                 :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')"
                 style="margin-top: 20px;" :pagination="false">
          <span slot="equipment_status"
                slot-scope="value,record">{{
              equipmentStatusList[value] ? equipmentStatusList[value].dicvalue : ''
            }}</span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.id">详情</a-menu-item>
                <!--                <a-menu-item :key="'edit-'+record.id">修改</a-menu-item>-->
                <!--                <a-menu-item :key="'delete-'+record.id">删除</a-menu-item>-->
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer
                      @change="pageChange" @showSizeChange="onShowSizeChange"
                      :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <detail-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData"
                  @get-operation-result="getTableData"></detail-modal>
    <ShowChart :visible.sync="chartModalVisible" :detailData="chartQueryParams"></ShowChart>
  </page-container>
</template>
<script>
import moment from "moment";
import {mapGetters, mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getCache, getItemFromArrayByKey, getTimeRange} from "U/index";
import deptselect from "@/mixins/deptselect";
import {
  deleteOneEcHistoryAnalysis,
  getEcHistoryAnalysisListByCondition,
  exportEcHistoryAnalysisExcel,
  getAllAllocationEcRecordIdAndCodeListByCondition,
} from "A/energyconsumption";
import DetailModal from "./detailModal.vue";
import ecCommont from "../mixins/ecMixin"
import ShowChart from "./showChart.vue";
import {getMonitorPointNameListByCondition} from "A/monitoring";

export default {
  name: "directories",
  mixins: [deptselect, pagination, ecCommont],
  components: {
    DetailModal, ShowChart
  },
  data() {
    return {
      moment,
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      yearOptions: [],
      queryParams: {
        classification: '公共用电',
        year: '',
        userdepsid:'',
        monitorpointnum:"",
        monitorpointname:'',
        warning_status:"",
        yearList:[]
      },
      tableColumns: [
        {
          title: '项目名称',
          dataIndex: 'userdepname',
          key: 'userdepname',
          ellipsis: true,
        },
        {
          title: '能耗分类',
          dataIndex: 'classification',
          key: 'classification',
          ellipsis: true,
        },
        {
          title: '年份',
          dataIndex: 'year',
          key: 'year',
          ellipsis: true,
        },
        {
          title: '一月',
          dataIndex: 'jan',
          key: 'jan',
          ellipsis: true,
          customRender: (text, record) => {
            const color = Math.abs(Number(text)-Number(record.month_one))>Number(record.month_one)*0.05 ? 'red' : '';
            return {
              children: text,
              attrs: {
                style: `color: ${color}`,
              },
            };
          },
        },
        {
          title: '二月',
          dataIndex: 'feb',
          key: 'feb',
          ellipsis: true,
          customRender: (text, record) => {
            const color = Math.abs(Number(text)-Number(record.month_two))>Number(record.month_two)*0.05 ? 'red' : '';
            return {
              children: text,
              attrs: {
                style: `color: ${color}`,
              },
            };
          },
        },
        {
          title: '三月',
          dataIndex: 'mar',
          key: 'mar',
          ellipsis: true,
          customRender: (text, record) => {
            const color = Math.abs(Number(text)-Number(record.month_three))>Number(record.month_three)*0.05 ? 'red' : '';
            return {
              children: text,
              attrs: {
                style: `color: ${color}`,
              },
            };
          },
        },
        {
          title: '四月',
          dataIndex: 'apr',
          key: 'apr',
          ellipsis: true,
          customRender: (text, record) => {
            const color = Math.abs(Number(text)-Number(record.month_four))>Number(record.month_four)*0.05 ? 'red' : '';
            return {
              children: text,
              attrs: {
                style: `color: ${color}`,
              },
            };
          },
        },
        {
          title: '五月',
          dataIndex: 'may',
          key: 'may',
          ellipsis: true,
          customRender: (text, record) => {
            const color = Math.abs(Number(text)-Number(record.month_five))>Number(record.month_five)*0.05 ? 'red' : '';
            return {
              children: text,
              attrs: {
                style: `color: ${color}`,
              },
            };
          },
        },
        {
          title: '六月',
          dataIndex: 'jun',
          key: 'jun',
          ellipsis: true,
          customRender: (text, record) => {
            const color = Math.abs(Number(text)-Number(record.month_six))>Number(record.month_six)*0.05 ? 'red' : '';
            return {
              children: text,
              attrs: {
                style: `color: ${color}`,
              },
            };
          },
        },
        {
          title: '七月',
          dataIndex: 'jul',
          key: 'jul',
          ellipsis: true,
          customRender: (text, record) => {
            const color = Math.abs(Number(text)-Number(record.month_seven))>Number(record.month_seven)*0.05 ? 'red' : '';
            return {
              children: text,
              attrs: {
                style: `color: ${color}`,
              },
            };
          },
        },
        {
          title: '八月',
          dataIndex: 'aug',
          key: 'aug',
          ellipsis: true,
          customRender: (text, record) => {
            const color = Math.abs(Number(text)-Number(record.month_eight))>Number(record.month_eight)*0.05 ? 'red' : '';
            return {
              children: text,
              attrs: {
                style: `color: ${color}`,
              },
            };
          },
        },
        {
          title: '九月',
          dataIndex: 'sept',
          key: 'sept',
          ellipsis: true,
          customRender: (text, record) => {
            const color = Math.abs(Number(text)-Number(record.month_nine))>Number(record.month_nine)*0.05 ? 'red' : '';
            return {
              children: text,
              attrs: {
                style: `color: ${color}`,
              },
            };
          },
        },
        {
          title: '十月',
          dataIndex: 'oct',
          key: 'oct',
          ellipsis: true,
          customRender: (text, record) => {
            const color = Math.abs(Number(text)-Number(record.month_ten))>Number(record.month_ten)*0.05 ? 'red' : '';
            return {
              children: text,
              attrs: {
                style: `color: ${color}`,
              },
            };
          },
        },
        {
          title: '十一月',
          dataIndex: 'nov',
          key: 'nov',
          ellipsis: true,
          customRender: (text, record) => {
            const color = Math.abs(Number(text)-Number(record.month_eleven))>Number(record.month_eleven)*0.05 ? 'red' : '';
            return {
              children: text,
              attrs: {
                style: `color: ${color}`,
              },
            };
          },
        },
        {
          title: '十二月',
          dataIndex: 'dece',
          key: 'dece',
          ellipsis: true,
          customRender: (text, record) => {
            const color = Math.abs(Number(text)-Number(record.month_twelve))>Number(record.month_twelve)*0.05 ? 'red' : '';
            return {
              children: text,
              attrs: {
                style: `color: ${color}`,
              },
            };
          },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: 'operation'},
          width: 70
        },
      ],
      ecPositionAllList: [],
      ecCategoryAllList: [],
      ecRecordAllIdAndCodeList: [],


      copyData: {},
      userdepidCascaderSelected: [],
      deviceCodeList: [],
      equipmentStatusList: [],
      equipmentCategoryMap: {},
      equipmentCategoryList: [],
      equipmentChildCategoryList: [],
      equipmentChildCategoryMap: [],
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      //操作按钮权限
      btnList: [],
      menu: {},
      chartModalVisible: false,
      chartQueryParams: null,
      monitorpointList:[],
      classIndex:""
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['operationMenuTree']),
    pageTitle() {
      return "能耗历史分析"
    },
    breadcrumb() {
      const pages = [{name: "设施设备管理", path: ""}]
      pages.push({name: "运行管理", path: ""})
      pages.push({name: "能耗管理", path: ""})
      pages.push({name: this.pageTitle, path: ""})
      return pages
    },
    //时段计算
    query_begin_time() {
      if (this.queryParams.year && this.queryParams.year.length !== 0) {
        return moment(this.queryParams.year).format("YYYY") + '-01-01';
      } else {
        return ''
      }
    },
    query_end_time() {
      if (this.queryParams.year && this.queryParams.year.length !== 0) {
        return moment(this.queryParams.year).format("YYYY") + '-12-31';
      } else {
        return ''
      }
    },
  },
  watch: {
    userdepidCascaderSelected(val) {
      if (val && val.length) {
        this.queryParams.userdepsid = val[val.length - 1];
      } else {
        this.queryParams.userdepsid = '';
      }
    },
    'queryParams.classification'(val){
      if (val && val.length) {
        this.classIndex=this.ecClassifyList.indexOf(val)+1
      }else{
        this.classIndex = ''
      }

    },
    'queryParams.type'(val) {
      if (val && val.length) {
        // if (this.queryParams.region === '') {
        //   let values = Object.values(this.ecCategoryList);
        //   this.ecCategoryAllList = []
        //   for (let value of values) {
        //     this.ecCategoryAllList = this.ecCategoryAllList.concat(value[val]);
        //   }
        //   this.ecCategoryAllList = [...new Set(this.ecCategoryAllList)];
        // } else {
        //   this.ecCategoryAllList = this.ecCategoryList[this.queryParams.region][val]
        // }
      } else {
        // if (this.queryParams.region === '') {
        //   let values = Object.values(this.ecCategoryList);
        //   this.ecCategoryAllList = []
        //   for (let value of values) {
        //     let _values = Object.values(value);
        //     for(let _value of _values){
        //       this.ecCategoryAllList = this.ecCategoryAllList.concat(_value);
        //     }
        //   }
        //   this.ecCategoryAllList = [...new Set(this.ecCategoryAllList)];
        // } else {
        //   let newObg=this.ecCategoryList[this.queryParams.region];
        //   let values = Object.values(newObg);
        //   this.ecCategoryAllList = []
        //   for (let value of values) {
        //     this.ecCategoryAllList = this.ecCategoryAllList.concat(value);
        //   }
        //   this.ecCategoryAllList = [...new Set(this.ecCategoryAllList)];
        // }
      }
    },
    'queryParams.region'(val) {
      if (val && val.length) {
        // if (this.queryParams.type === '') {
        //   let newObg=this.ecCategoryList[val];
        //   let values = Object.values(newObg);
        //   this.ecCategoryAllList = []
        //   for (let value of values) {
        //     this.ecCategoryAllList=this.ecCategoryAllList.concat(value);
        //   }
        //   this.ecCategoryAllList = [...new Set(this.ecCategoryAllList)];
        // } else {
        //   this.ecCategoryAllList = this.ecCategoryList[val][this.queryParams.type]
        // }
        this.ecPositionAllList = this.ecPositionList[val]
      } else {
        // if (this.queryParams.type === '') {
        //   let values = Object.values(this.ecCategoryList);
        //   this.ecCategoryAllList = []
        //   for (let value of values) {
        //     let _values = Object.values(value);
        //     for(let _value of _values){
        //       this.ecCategoryAllList=this.ecCategoryAllList.concat(_value);
        //     }
        //   }
        //   this.ecCategoryAllList = [...new Set(this.ecCategoryAllList)];
        // } else {
        //   let values = Object.values(this.ecCategoryList);
        //   this.ecCategoryAllList = []
        //   for (let value of values) {
        //     this.ecCategoryAllList=this.ecCategoryAllList.concat(value[this.queryParams.type]);
        //   }
        //   this.ecCategoryAllList = [...new Set(this.ecCategoryAllList)];
        // }
        let entries = Object.entries(this.ecPositionList);
        this.ecPositionAllList = [];
        for (let [key, value] of entries) {
          this.ecPositionAllList = this.ecPositionAllList.concat(value);
        }
      }
    },
  },
  created() {
    this.menu = getItemFromArrayByKey(this.operationMenuTree, "menuid", this.$route.params.menuid, "children")
    for (let i = 0; i < this.menu.children.length; i++) {//获取菜单页面内操作权限
      this.btnList.push(this.menu.children[i].menuname)
    }
    this.init();
    this.getTableData()
    this.initDeptOptionsAll();
    this.getYearOptions();
    this.getMonitorPointNameList();
    //获取所有本项目的能耗表编号
    //this.getAllEcRecordIDAndCode();
  },
  methods: {
    init() {
      let values = Object.values(this.ecCategoryList);
      this.ecCategoryAllList = []
      for (let value of values) {
        let _values = Object.values(value);
        for (let _value of _values) {
          this.ecCategoryAllList = this.ecCategoryAllList.concat(_value);
        }
      }
      this.ecCategoryAllList = [...new Set(this.ecCategoryAllList)];
      let entries = Object.entries(this.ecPositionList);
      this.ecPositionAllList = [];
      for (let [key, value] of entries) {
        this.ecPositionAllList = this.ecPositionAllList.concat(value);
      }
    },
    getMonitorPointNameList() {
      getMonitorPointNameListByCondition({userdepsid: this.userInfo.userdepid}).then(res => {
        if (res && res.returncode == '0') {
          this.monitorpointList = res.item
        }
      })
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;

      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
        //begin_time: this.query_begin_time === '' ? moment(new Date()).format("YYYY") + '-01-01 00:00:00' : this.query_begin_time + " 00:00:00",
        //end_time: this.query_end_time === '' ? moment(new Date()).format("YYYY") + '-12-31 23:59:59' : this.query_end_time + " 23:59:59",
        warning_status_enable:this.queryParams.warning_status==='2'?'1':'',
        warning_status_diable:this.queryParams.warning_status==='3'?'1':'',
        income_type:this.queryParams.classification?this.ecClassifyList.indexOf(this.queryParams.classification)+1:""
      };
      getEcHistoryAnalysisListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    getYearOptions() {
      this.yearOptions = [];
      let currentYear = moment().year();
      for (let i = 3; i > -1; i--) {
        this.yearOptions.push(currentYear - i + '');
      }
    },
    resetQueryParams() {
      this.userdepidCascaderSelected = []
      this.queryParams.yearList = []
      this.$refs.queryForm.resetFields();
    },
    getAllEcRecordIDAndCode() {
      let params = {
        ...this.queryParams,
      };
      getAllAllocationEcRecordIdAndCodeListByCondition(params).then(res => {
        if (res && res.returncode == '0') {
          this.ecRecordAllIdAndCodeList = res.item;
        }
      })
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'id', id);
      if (type == 'delete') {
        this.deleteConfirm(id, record)
      } else {
        this.showModal(type, record)
      }
    },
    showModal(type, record) {
      if (type === 'add') {
        this.modalVisible = true
        this.modalShowType = type
      } else if (type === 'detail') {
        this.modalDetailData = record
        this.modalShowType = type
        this.modalVisible = true
      } else if (type === 'edit') {
        this.modalDetailData = record
        this.modalShowType = type
        this.modalVisible = true
      }
    },
    exportModal() {
      this.$confirm('你确认要按照所选筛选条件导出数据吗？', {
        type: 'warning',
        centered: true,
      }).then(() => {
        this.exportHistoryAnalysis()
      }).catch(() => {
      });
    },
    exportHistoryAnalysis() {
      let params = {
        ...this.queryParams,
        //begin_time: this.query_begin_time === '' ? moment(new Date()).format("YYYY") + '-01-01 00:00:00' : this.query_begin_time + " 00:00:00",
        //end_time: this.query_end_time === '' ? moment(new Date()).format("YYYY") + '-12-31 23:59:59' : this.query_end_time + " 23:59:59",
        warning_status_enable:this.queryParams.warning_status==='2'?'1':'',
        warning_status_diable:this.queryParams.warning_status==='3'?'1':'',
        income_type:this.queryParams.classification?this.ecClassifyList.indexOf(this.queryParams.classification)+1:""
      }
      this.showLoading();
      exportEcHistoryAnalysisExcel(params).then((res) => {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(() => {
        this.hideLoading();
      })
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据?', {
        type: 'warning',
        centered: true,
      }).then(() => {
        this.delete(record.id);
      }).catch(() => {

      });
    },
    delete(id) {
      this.showLoading();
      if (id) {
        let params = {
          id
        };
        deleteOneEcHistoryAnalysis(params).then(res => {
          this.hideLoading();
          if (res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          } else {
            this.$message.error(res.errormsg || '操作失败');
          }
        })
      }
    },
    rowSelectChange(selectedRowKeys, selectedRows) {
      this.batchrowids = selectedRowKeys.join('|');
    },
    customRow(record, index) {
      return {
        on: { // 事件
          click: () => {
            this.modalDetailData = record
            this.modalShowType = "detail"
            this.modalVisible = true
          },
        },
      }
    },
    showChartModal() {
      if (this.queryParams.userdepsid === '') {
        this.$message({
          message: "请选择一个项目",
          type: "warning",
        });
      } else if (this.queryParams.classification === '') {
        this.$message({
          message: "请选择一个能耗分类",
          type: "warning",
        });
      } else if (this.queryParams.yearList.length  === 0) {
        this.$message({
          message: "请至少选择一个年份",
          type: "warning",
        });
      } else {
        this.chartQueryParams = null;
        for (let item of this.monitorpointList) {
          if (item.userdepid === Number(this.queryParams.userdepsid)) {
            this.queryParams.monitorpointnum = item.monitorpointnum
            this.queryParams.monitorpointname = item.monitorpointname
          }
        }
        this.chartQueryParams = {
          ...this.queryParams,
          //begin_time: this.query_begin_time === '' ? moment(new Date()).format("YYYY") + '-01-01 00:00:00' : this.query_begin_time + " 00:00:00",
          //end_time: this.query_end_time === '' ? moment(new Date()).format("YYYY") + '-12-31 23:59:59' : this.query_end_time + " 23:59:59",
          //year:this.queryParams.year === '' ?moment(new Date()).format("YYYY"):this.queryParams.year
          warning_status_enable:this.queryParams.warning_status==='2'?'1':'',
          warning_status_diable:this.queryParams.warning_status==='3'?'1':'',
          income_type:this.queryParams.classification?this.classIndex:""
        }
        this.chartModalVisible = true
      }
    }
  },
}
</script>
<style scoped>

</style>