<template>
  <div>
    <a-modal v-model="modalVisible" title="能耗历史分析" :width="1300" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">关闭</a-button>
      </template>
      <div class="box-container" style="margin-top: 10px;">
        <div class="box-container-inner">
          <div class="table-header">
            <h3 class="table-title"></h3>
          </div>
          <div class="bar" id="bar_left" style="width: 100%;height: 400px"></div>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import {mapState} from 'vuex'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import {
  getEcHistoryAnalysisListByCondition,
} from "A/energyconsumption";
import {getOperatingStatementListByCondition} from "A/businessmanagement"


import ecCommont from "../mixins/ecMixin"
import * as echarts from "echarts";

export default {
  mixins: [areaselect, deptselect, ecCommont],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    },
    ecRecordAllIdAndCodeData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      modalBodyStyle: {
        height: '500px',
        overflowY: 'auto',
      },
      modalVisible: false,
      xDataList: [],
      xLabelList: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"],
      xLabelMap: {},
      maxXNum: null,
      xInterval: null,
      yDataList: [],
      total: '',
      maxYNum: null,
      yInterval: null,
      xPlanDataList: [],

      formData: {},
      //这里面的数据属于必填项
      formRules: {},
      classification: '',
      theLegend: [],
      theYearLegend: {},
      xPreDataList: [],
      xPrePreDataList: [],
      //年度，用来接受传递过来的年度参数
      year: "",
      yearList: [],
      AllDataList: [],
      colorList:['#e8a138','#605454','#9a2b11','#959a11','#9a6a11','#118a9a','#3f119a'],
      chartSeries: []
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      if (this.showType == 'add') {
        return '新增'
      } else if (this.showType == 'edit') {
        return '修改'
      } else if (this.showType == 'detail') {
        return '详情'
      } else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if (val) {
        console.log(this.detailData)
        this.chartSeries= [
          {
            //当年能耗计划
            name: '当年计划',
            type: 'line',
            tooltip: {
              valueFormatter: function (value) {
                return value;
              }
            },
            data: null,
            itemStyle: {
              color: '#225fb0',
            },
            lineStyle: {
              width: 3,
              type: 'solid',
            },
          }]
        this.modalVisible = true;
        this.classification = this.detailData.classification
        this.yearList = this.detailData.yearList
        this.yearList.sort(function (a, b) {
          return a - b;
        });
        for (let i = 0; i < this.yearList.length; ++i) {
          this.theLegend[i] = this.yearList[i];
          var serieSubObject = {
            name: this.yearList[i],
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value;
              }
            },
            data: null,
            itemStyle: {
              color: this.colorList[i],
            },
          }
          this.chartSeries.push(serieSubObject)
        }
        this.theLegend[this.yearList.length] = "当年计划";
        this.resetData();
        this.getTableData();
      }
    },
    modalVisible(val) {
      if (!val) {
        this.resetData();
        this.$emit('get-operation-result', 'success', '操作成功');
        this.$emit('update:visible', false);
      }
    }
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    getEcPlan() {
      return new Promise(resolve => {

        //获取当年能耗计划,因为涉及到一个最大值的问题
        let params = {
          category: "能耗用量计划",
          decision_type: "计划",
          finance_type: "支出",
          monitorpointname: this.detailData.monitorpointname,
          monitorpointnum: this.detailData.monitorpointnum,
          status: "0",
          year: moment(new Date()).format("YYYY"),
          is_confirmed: "1",
          income_type:this.ecClassifyList.indexOf(this.classification)+1
        };
        getOperatingStatementListByCondition(params).then(res => {
          if (res && res.returncode === '0') {
            if (res.item && res.item.length > 0) {
              let income_type = res.item[0].income_type;
              let _classification = this.ecClassifyList[Number(income_type) - 1];
              if (_classification === this.classification) {
                this.xPlanDataList[0] = res.item[0].month_one;
                this.xPlanDataList[1] = res.item[0].month_two;
                this.xPlanDataList[2] = res.item[0].month_three;
                this.xPlanDataList[3] = res.item[0].month_four;
                this.xPlanDataList[4] = res.item[0].month_five;
                this.xPlanDataList[5] = res.item[0].month_six;
                this.xPlanDataList[6] = res.item[0].month_seven;
                this.xPlanDataList[7] = res.item[0].month_eight;
                this.xPlanDataList[8] = res.item[0].month_nine;
                this.xPlanDataList[9] = res.item[0].month_ten;
                this.xPlanDataList[10] = res.item[0].month_eleven;
                this.xPlanDataList[11] = res.item[0].month_twelve;
              }
              let _object= this.chartSeries[0];
              _object.data = this.xPlanDataList
            }
            resolve();
          }
        })
      });
    },
    getBeginAndEndTime(val) {
      let year = Number(this.detailData.year) - val
      this.detailData.begin_time = year + '-01-01 00:00:00';
      this.detailData.end_time = year + '-12-31 23:59:59';
    },
    handleDataList(dataList, _object) {
      dataList[0] = _object.jan ? _object.jan : 0;
      dataList[1] = _object.feb ? _object.feb : 0;
      dataList[2] = _object.mar ? _object.mar : 0;
      dataList[3] = _object.apr ? _object.apr : 0;
      dataList[4] = _object.may ? _object.may : 0;
      dataList[5] = _object.jun ? _object.jun : 0;
      dataList[6] = _object.jul ? _object.jul : 0;
      dataList[7] = _object.aug ? _object.aug : 0;
      dataList[8] = _object.sept ? _object.sept : 0;
      dataList[9] = _object.oct ? _object.oct : 0;
      dataList[10] = _object.nov ? _object.nov : 0;
      dataList[11] = _object.dece ? _object.dece : 0;
    },
    getThisYear() {
      return new Promise(resolve => {
        let params = {
          ...this.detailData,
        };
        getEcHistoryAnalysisListByCondition(params).then(_res => {
          if (_res && _res.returncode === '0') {
            //开始整理数值
            if (_res.item && _res.item.length > 0) {
              let _object = _res.item[0];
              this.handleDataList(this.xDataList, _object);
            } else {
              for (let i = 0; i < 12; ++i) {
                this.xDataList[i] = 0
              }
            }
            resolve();
          }
        })
      });

    },
    getPreYear() {
      return new Promise(resolve => {
        this.getBeginAndEndTime(1)
        let params = {
          ...this.detailData,
        };
        getEcHistoryAnalysisListByCondition(params).then(_res => {
          if (_res && _res.returncode === '0') {
            //开始整理数值
            if (_res.item && _res.item.length > 0) {
              let _object = _res.item[0];
              this.handleDataList(this.xPreDataList, _object);
            } else {
              for (let i = 0; i < 12; ++i) {
                this.xPreDataList[i] = 0
              }
            }
            resolve();
          }
        })
      });

    },
    getPrePreYear() {
      return new Promise(resolve => {
        this.getBeginAndEndTime(2)
        let params = {
          ...this.detailData,
        };
        getEcHistoryAnalysisListByCondition(params).then(_res => {
          if (_res && _res.returncode === '0') {
            //开始整理数值
            if (_res.item && _res.item.length > 0) {
              let _object = _res.item[0];
              this.handleDataList(this.xPrePreDataList, _object);
            } else {
              for (let i = 0; i < 12; ++i) {
                this.xPrePreDataList[i] = 0
              }
            }
            resolve();
          }
        })
      });
    },
    getTableData() {
      this.resetEchartsData();
      Promise.all([this.getEcPlan(), this.getAllData()]).then(() => {
        //this.maxXNum = Math.max(...this.xPlanDataList, ...this.xDataList, ...this.xPreDataList, ...this.xPrePreDataList);
        let maxXNum_one = Math.max(...this.AllDataList.map(subArray => Math.max(...subArray)));
        let maxXNum_two = Math.max(...this.xPlanDataList);
        let arr = [];
        arr.push(maxXNum_one)
        arr.push(maxXNum_two)
        this.maxXNum = Math.max(...arr);

        this.total = 1
        let base = 8
        while (this.maxXNum > base) {
          base = base + 8
        }
        this.maxXNum = base
        this.xInterval = this.maxXNum / 8
        //this.calcData()

        let that = this
        setTimeout(() => {
          that.initCharts()
        }, 500)
      });
    },
    getAllData() {
      return new Promise(resolve => {
        let params = {
          ...this.detailData,
          warning_status_enable: this.detailData.warning_status === '2' ? '1' : '',
          warning_status_diable: this.detailData.warning_status === '3' ? '1' : '',
          income_type:this.ecClassifyList.indexOf(this.classification)+1
        };
        getEcHistoryAnalysisListByCondition(params).then(res => {
          if (res && res.returncode == '0') {
            //this.AllDataList = res.item;
            //开始整理数值
            if (res.item && res.item.length > 0) {
              for (let i = 0; i < res.item.length; ++i) {
                let _object = res.item[i];
                let newArr = []
                this.handleDataList(newArr, _object);
                this.AllDataList.push(newArr)
                for (let value of this.chartSeries){
                  if(value.name===_object.year.slice(0,-1)){
                    value.data=newArr
                  }
                }
              }
            }
            resolve();
          }
        })
      });
    },
    resetEchartsData() {
      this.xDataList = []
      this.xLabelMap = {}
      this.maxXNum = null
      this.xInterval = null
      this.yDataList = []
      this.maxYNum = null
      this.yInterval = null
      this.xPrePreDataList = []
      this.xPreDataList = []
    },
    onChange(option) {
      this.formData.host_num = option.replace(/\s*/g, "")
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    resetData() {
      if (this.$refs.modalForm) {
        this.formData.userdep_id = ''
        this.$refs.modalForm.resetFields();
      }
    },
    initDetail() {
      if (this.detailData) {
        if (this.showType == 'edit' || this.showType == 'detail') {
        } else {
          this.resetData()
        }
      }
    },
    calcData() {
      let totalNum = this.total
      let yData = 0;
      for (let i = 0; i < this.xDataList.length; i++) {
        yData += this.xDataList[i] / totalNum * 100
        this.yDataList.push(yData.toFixed(2))
      }
    },
    initCharts() {
      let chartDom = document.getElementById('bar_left');
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        title: {
          x: "center",
          show: true, //是否显示
          text: this.classification,
          textStyle: {
            color: "#000000", // 主标题文字的颜色。
            fontSize: 18, // 字体大小
            fontWeight: 'bold',
          },
          textAlign: "center",
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#e20937'
            }
          }
        },
        toolbox: {
          feature: {},
          iconStyle: {
            normal: {
              color: '#000000',//设置颜色
            }
          }
        },
        legend: {
          data: this.theLegend,
          x: 'center',
          y: 'bottom',
        },
        xAxis: [
          {
            type: 'category',
            data: this.xLabelList,
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: {
              show: true,
              formatter: function (value) {
                var ret = "";//拼接加\n返回的类目项
                var maxLength = 3;//每项显示文字个数
                var valLength = value.length;//X轴类目项的文字个数
                var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
                if (rowN > 1)//如果类目项的文字大于3,
                {
                  for (var i = 0; i < rowN; i++) {
                    var temp = "";//每次截取的字符串
                    var start = i * maxLength;//开始截取的位置
                    var end = start + maxLength;//结束截取的位置
                    //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                    temp = value.substring(start, end) + "\n";
                    ret += temp; //凭借最终的字符串
                  }
                  return ret;
                } else {
                  return value;
                }
              },
              textStyle: {
                color: '#000000',
                fontWeight: 'bold',
              }
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            // name: '报警情况',
            nameTextStyle: {color: '#000000'},
            min: 0,
            max: this.maxXNum,
            interval: this.xInterval,
            axisLabel: {
              formatter: '{value}',
              textStyle: {
                color: '#000000',
                fontSize: 15,
                fontWeight: 'bold',
              }
            },
            splitArea: {show: false},  //去除网格区域
            splitLine: {//去除网格线
              show: true,
              lineStyle: {
                color: ['rgb(237,125,49)'],
                width: 1.3,
                type: 'solid'
              }
            }
          },
        ],
        series: this.chartSeries
      };
      option && myChart.setOption(option);
    }
  }
}
</script>
<style lang="scss" scoped>
.bar {
  pointer-events: auto;
}
</style>